<template>
  <div v-if="isShowPage" class="wrap">
    <!-- header : END -->
    <back-title-basket :title="$t('Join.Join')" to="/auth/join/home" />
    <!-- header : END -->
    <!-- body : START -->
    <div class="layout_body box_p60">
      <div class="joinForm_box">
        <div>
          <ul class="wrap_flex userinfo">
            <li>
              <label for="mem_phone"> {{ $t('Join.IdVerification') }}</label>

              <template v-if="isAuth === false">
                <button class="auth_btn black_btn" @click="handleClickPhone">
                  {{ $t('Join.DoIdVerification') }}
                </button>
              </template>

              <template v-else>
                <button class="auth_btn complate_btn">
                  {{ $t('Join.VerificationComplete') }}
                </button>
              </template>
            </li>
            <li>
              <label for="mem_phone"> {{ $t('Join.PhoneNumber') }}</label>
              <input-number
                id="mem_phone"
                v-model="form.mem_phone"
                :maxLength="11"
                :placeholder="$t('Join.EnterHyphen')"
                name="mem_phone"
                readonly="readonly"
                @click="handleClickPhone"
              />
              <p v-show="error.mem_phone" class="error">
                {{ $t('Join.ErrorEmptyPhone') }}
              </p>
            </li>
            <li>
              <label for="mem_username">{{ $t('Join.UserName') }}</label>
              <input
                id="mem_username"
                v-model="form.mem_username"
                :placeholder="$t('Join.EnterName')"
                maxLength="15"
                name="mem_username"
                readonly="readonly"
                type="text"
                @click="handleClickPhone"
              />
              <p v-show="error.mem_username" class="error">
                {{ $t('Join.ErrorName') }}
              </p>
            </li>
            <li>
              <label for="mem_userid">{{ $t('Join.ID') }}</label>
              <input
                id="mem_userid"
                v-model="form.mem_userid"
                :placeholder="$t('Join.EnterId')"
                maxLength="15"
                name="mem_userid"
                type="text"
                @focusout="handleFocusOutName"
                @input="handleInputId"
              />
              <p v-show="error.duplicateId" class="error">
                {{ $t('Join.ErrorOverlapId') }}
              </p>
              <p v-show="error.mem_userid" class="error">
                {{ $t('Join.ErrorId') }}
              </p>
              <p v-show="error.sameNumberId" class="error">
                {{ $t('Join.ErrorIdThree') }}
              </p>
              <p v-show="error.lengthId" class="error">
                {{ $t('Join.ErrorIdLength') }}
              </p>
            </li>
            <li>
              <label for="mem_password">{{ $t('Join.Password') }}</label>
              <input
                id="mem_password"
                v-model="form.mem_password"
                :placeholder="$t('Join.EnterPw')"
                maxLength="15"
                name="mem_password"
                type="password"
                @focusout="handleFocusOutName"
                @input="handleInputPw"
              />
              <p v-show="error.mem_password" class="error">
                {{ $t('Join.ErrorPwMixed') }}
              </p>
              <p v-show="error.sameNumberPw" class="error">
                {{ $t('Join.ErrorPwSequence') }}
              </p>
              <p v-show="error.isShoNoticeMem_pw" class="error">
                {{ $t('Join.ErrorPwWord') }}
              </p>
            </li>
            <li>
              <label for="userPwchk">{{ $t('Join.UserPwAgain') }}</label>
              <input
                id="userPwchk"
                v-model="form.pwCk"
                :placeholder="$t('Join.EnterPwAgain')"
                maxLength="15"
                name="pwCk"
                type="password"
                @focusout="handleFocusOutName"
                @input="handleInputPwChk"
              />
              <p v-show="error.pwCk" class="error">
                {{ $t('Join.ErrorPwSame') }}
              </p>
            </li>
            <li>
              <label for="mem_email">{{ $t('Join.UserEmail') }}</label>
              <input
                id="mem_email"
                v-model="form.mem_email"
                :placeholder="$t('Join.EnterEmail')"
                maxLength="40"
                name="mem_email"
                type="text"
                @focusout="handleFocusOutName"
              />
              <p v-show="error.mem_email" class="error">
                {{ $t('Join.ErrorEmail') }}
              </p>
            </li>
            <li>
              <label for="recoId">{{ $t('Join.Recommender') }}</label>
              <input
                id="recoId"
                v-model="recommend_id"
                :placeholder="$t('Join.EnterRecommender')"
                class="btn_group"
                maxLength="15"
                type="text"
              />
              <button class="black_btn" @click="handleClickRecommendId">
                {{ $t('Join.Confirm') }}
              </button>
            </li>
          </ul>
        </div>
        <div>
          <h6 class="allPage_tit">{{ $t('Join.JoinAgree') }}</h6>
          <ul class="useragree wrap_flex">
            <li class="allChk">
              <ks-check-box :item="agrees.allChk" @input="handleChangeAllChk" />
            </li>
            <li>
              <ks-check-box v-model="agree.allTerms" :item="agrees.allTerms" />
            </li>
            <li class="agree_sub">
              <p>
                {{ $t('Join.UsageTerm')
                }}<a class="btn_pop_open" @click="isShowUsageTerm = true">
                  {{ $t('Join.ContentOpen') }}
                </a>
              </p>
              <p>
                {{ $t('Join.PersonalTerm') }}
                <a class="btn_pop_open" @click="isShowPersonalInfo = true">
                  {{ $t('Join.ContentOpen') }}
                </a>
              </p>
            </li>
            <li>
              <ks-check-box v-model="agree.up14" :item="agrees.up14" />
            </li>
            <li style="justify-content: space-between">
              <ks-check-box v-model="form.mem_receive_sms" :item="agrees.sms" />
              <a
                class="btn_pop_open"
                style="
                  font-size: 12px;
                  text-decoration: underline;
                  cursor: pointer;
                "
                @click="isShowMarkettingPopup = true"
              >
                {{ $t('Join.ContentOpen') }}
              </a>
            </li>
          </ul>
          <button
            class="black_btn font4 btn_pop_open"
            type="button"
            @click="handleClickSubmit"
          >
            {{ $t('Join.Confirm') }}
          </button>
        </div>
      </div>
    </div>

    <sild-footer />

    <!-- <usage-term v-if="isShowUsageTerm" @close="isShowUsageTerm = false" /> -->
    <usage-popup v-if="isShowUsageTerm" @close="isShowUsageTerm = false" />
    <personal-info
      v-if="isShowPersonalInfo"
      @close="isShowPersonalInfo = false"
    />
    <marketting-popup
      v-if="isShowMarkettingPopup"
      @close="isShowMarkettingPopup = false"
    />
    <phone-form
      v-if="isShowPhoneForm"
      :datas="phoneDatas"
      @close="isShowPhoneForm = false"
      @complate="handleComplateAuth"
    />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import KsCheckBox from '@/components/Inputs/KsCheckBox/KsCheckBox.vue';
import AuthAPI from '@/apis/AuthAPI';
import useJoin from '@/composables/user/useJoin';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import useAlert from '../../composables/dialogs/useAlert';
import PersonalInfo from './popups/PersonalInfo.vue';

// import UsageTerm from './popups/UsageTerm.vue';
import PhoneForm from './PhoneForm.vue';
import InputNumber from '../../components/Inputs/InputNumber/InputNumber.vue';
import useConfirm from '../../composables/dialogs/useConfirm';
import UsagePopup from '@/views/settings/popups/UsagePopup.vue';
import MarkettingPopup from '@Views/settings/popups/MarkettingPopup';

export default {
  components: {
    MarkettingPopup,
    UsagePopup,
    SildFooter,
    KsCheckBox,
    BackTitleBasket,
    PersonalInfo,
    // UsageTerm,
    PhoneForm,
    InputNumber,
  },
  setup(_, context) {
    const urlCode = process.env.VUE_APP_URLCODE_JOIN;
    const { merge } = useJoin();
    const { showAlert } = useAlert();
    const { showConfirm } = useConfirm();

    const cert_num = localStorage.getItem('cert_num');
    console.log(cert_num);
    const state = reactive({
      isShowPointPolicy: false,
      error: {},
      isAuth: true,
      form: {
        mem_phone: '01029294967',
        mem_username: '유재민',
        mem_userid: '',
        mem_password: '',
        mem_email: '',
        mem_age: 30,
        mem_gender: 'm',

        recommend_id: '',

        is_recommend: false,
        is_rcv_sms: false,
        pwCk: '',
      },
      recommend_id: '',
      agree: {
        up14: false,
        allTerms: false,
        mem_receive_sms: false,
      },

      phoneDatas: null,

      isShowUsageTerm: false,
      isShowPersonalInfo: false,
      isShowPhoneForm: false,
      isShowMarkettingPopup: false,
      isShowPage: !cert_num,
    });

    const agrees = {
      allTerms: {
        key: 'allTerms',
        label: context.root.$t('Join.AllTerms'),
      },
      up14: {
        key: 'up14',
        label: context.root.$t('Join.Age14Over'),
      },
      sms: {
        key: 'sms',
        label: context.root.$t('Join.Marketing'),
      },
      allChk: {
        key: 'allChk',
        label: context.root.$t('Join.AllChk'),
      },
    };

    const handleComplateAuth = (data) => {
      if (Number(data.is_join) > 0) {
        showConfirm(context.root.$t('Join.AlertJoin'), () => {
          context.root.$router.replace('/auth/find-id');
        });
      } else {
        state.isAuth = true;
        state.form.mem_age = data.birth_day;
        state.form.mem_username = data.name;
        state.form.mem_phone = data.phone_no;
        state.form.mem_gender = data.gender === 0 ? 'man' : 'woman';
      }
      localStorage.setItem('cert_num', '');
    };
    const checkAuth = async () => {
      const { data } = await AuthAPI.getMemberAuthInfo({
        cert_num,
      });

      if (data !== null) {
        handleComplateAuth(data);
      }

      state.isShowPage = true;
    };

    cert_num && checkAuth();

    const validateObj = {
      mem_phone: /^01([0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{4})$/,
      mem_username: /^[가-힣]{2,}|[a-zA-Z]{2,}\s[a-zA-Z]{2,}$/,
      mem_email:
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,

      mem_userid(value) {
        const reg = /^[a-z]+[a-z0-9]{6,14}$/g;
        const error = {
          mem_userid: false,
          sameNumberId: false,
          lengthId: false,
        };
        if (!reg.test(value)) {
          error.mem_userid = true;
        } else {
          error.sameNumberId = checkSameNumber(value);
        }
        if (value.length < 6 || value.length > 15) {
          error.lengthId = true;
        }

        return error;
      },
      mem_password(value) {
        let reg = /(?=.*?[a-z])(?=.*?[A-Z])/;
        let reg2 = /[~!@#$%^&*()_+|<>?:{}]/;

        const error = {
          mem_password: false,
          sameNumberPw: false,
          isShoNoticeMem_pw: false,
        };

        if (!reg.test(value) || !reg2.test(value) || value.length < 6) {
          error.mem_password = true;
        } else {
          error.sameNumberPw = checkSameNumber(value);
        }

        return error;
      },
      pwCk(value) {
        const error = {
          pwCk: false,
        };

        error.pwCk = value !== state.form.mem_password;
        return error;
      },
    };

    const validate = async () => {
      await validateInputValues();

      let isSubmit = true;

      Object.keys(state.error).forEach((key) => {
        if (isSubmit) isSubmit = !state.error[key];
      });

      isSubmit = isSubmit && state.agree.up14;

      return isSubmit;
    };

    const validateInputValues = async () => {
      let error = {};

      Object.keys(validateObj).forEach((key) => {
        error = { ...error, ...validateByName(key) };
      });
      error.duplicateId = await isDuplicateId();

      state.error = error;
    };

    const validateByName = (name) => {
      if (typeof validateObj[name] === 'object') {
        const error = {};

        error[name] = !validateObj[name].test(state.form[name]);

        return error;
      }

      return validateObj[name](state.form[name]);
    };

    const isDuplicateId = async () => {
      const { data } = await AuthAPI.checkId(state.form['mem_userid']);
      return data.count > 0;
    };

    return {
      ...toRefs(state),
      agrees,
      handleComplateAuth,
      async handleClickSubmit() {
        if (!(await validate())) {
          return showAlert(context.root.$t('Join.AlertChk'));
        }

        if (!state.agree.allTerms) {
          return showAlert(context.root.$t('Join.AlertAgree'));
        }

        const { data } = await AuthAPI.join(state.form);

        if (data.result) {
          localStorage.setItem('mem_id', data.data.mem_id);

          state.form.mem_id = data.data.mem_id;

          merge(state.form);

          context.root.$router.push('/auth/join2');
        } else {
          showAlert(data.result_msg);
        }
      },

      handleChangeAllChk(checked) {
        state.agree = {
          up14: checked,
          allTerms: checked,
        };

        state.form.mem_receive_sms = checked;
      },

      async handleFocusOutName(e) {
        const error = validateByName(e.target.name);

        if (e.target.name === 'mem_userid') {
          error.duplicateId = await isDuplicateId();
        }

        state.error = { ...state.error, ...error };
      },

      handleInputId() {
        state.form.mem_userid = state.form.mem_userid.toLowerCase();
      },
      handleInputPw() {
        return state.form.mem_password;
        // state.form.mem_password.toLowerCase();
        // state.form.mem_password = state.form.mem_password;
      },
      handleInputPwChk() {
        return state.form.pwCk;
        // state.form.pwCk = state.form.pwCk.toLowerCase();
      },
      async handleClickPhone() {
        console.log('-> urlCode', urlCode);
        // 팝업이 열려있는 경우 다시 열지않음
        if (state.isShowPhoneForm) return;

        // 본인인증이 완료된 경우 열지않음
        if (state.form.mem_phone !== '') return;

        const { data } = await AuthAPI.getPhoneInfo({
          code: urlCode,
          close_url: 'join',
        });

        localStorage.setItem('cert_num', data.certNum);
        console.log('!!!!!!!!!!!!!!!!!!!!!@!@!@!@!@!');
        console.log('-> data', data);
        console.log('!!!!!!!!!!!!!!!!!!!!!@!@!@!@!@!');

        state.phoneDatas = data;

        state.isShowPhoneForm = true;
      },
      async handleClickRecommendId() {
        const value = state.recommend_id;

        state.form.is_recommend = false;

        if (value.length === 0) {
          return showAlert(context.root.$t('Join.AlertRecommand'));
        }

        const { data } = await AuthAPI.checkId(value);

        if (data.count === 0) {
          return showAlert(context.root.$t('Join.AlertNone'));
        }

        state.form.is_recommend = true;
        state.form.recommend_id = value;

        return showAlert(context.root.$t('Join.AlertConfirm'));
      },
    };
  },
};

const sameNumber = ['012', '123', '234', '345', '456', '567', '678', '789'];

const checkSameNumber = (value) => {
  let isSameNumber = false;

  sameNumber.forEach((str) => {
    if (isSameNumber === false) {
      isSameNumber = value.indexOf(str) > -1;
    }
  });

  return isSameNumber;
};
</script>

<style>
.allChk {
  border-bottom: 1px solid #d9d9d9;
}
.useragree label {
  padding: 10px 0;
}

.auth_btn {
  line-height: 40px;
  margin: 0 !important;
  width: 100% !important;
}

.complate_btn {
  background: #c7c7c7;
  color: #fff;
}
</style>
