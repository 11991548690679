<template>
  <down-up-popup
    :title="$t('Join.EctAdd')"
    @close="handleClickClose"
    @confirm="handleConfirm"
  >
    <template slot="body">
      <input
        v-model="etc"
        class="pick_search"
        type="search"
        :placeholder="$t('Join.EctConcept')"
      />
    </template>
  </down-up-popup>
</template>

<script>
import DownUpPopup from '@/components/popups/DownUpPopup.vue';
import { ref } from 'vue-demi';

export default {
  components: { DownUpPopup },
  setup(_, context) {
    const etc = ref();

    return {
      etc,
      handleConfirm() {
        context.emit('confirm', etc.value);
      },
      handleClickClose() {
        context.emit('close');
      },
    };
  },
};
</script>

<style></style>
