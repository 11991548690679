<template>
  <div class="wrap">
    <back-title-basket :title="$t('Join.Join')" />
    <div class="layout_body box_p0">
      <!-- 회원가입 실패시 나오는 페이지-->
      <div>
        <div class="join_content wrap_flex center_flex">
          <p class="fail">
            {{ $t('Join.SystemError') }}
          </p>
        </div>

        <a class="black_btn pos_fixed" @click="$router.push('/auth/join')">
          {{ $t('Join.Join') }}
        </a>
      </div>
    </div>
    <sild-footer />
  </div>
</template>

<script>
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
export default {
  components: { BackTitleBasket, SildFooter },
};
</script>

<style></style>
