<template>
  <div class="wrap">
    <back-title-basket :title="$t('Join.Join')" />
    <div class="layout_body box_p0">
      <div>
        <div class="join_box">
          <!-- 회원가입 성공시 나오는 페이지-->
          <div class="join_content flex_col align_flex">
            <div class="join_img"></div>
            <p>{{ $t('Join.UserComplete') }}</p>
          </div>
        </div>
        <button
          type="button"
          class="black_btn font4 pos_fixed"
          @click="handleClickGoHome"
        >
          {{ $t('Join.Shopping') }}
        </button>
        <!-- <a href="/sild/main/index.php" class="">

        </a> -->
      </div>
      <!-- 회원가입 실패시 나오는 페이지-->
      <!-- <div style="display: none">
        <div class="join_content wrap_flex center_flex">
          <P class="fail"
            >시스템 장애로 인하여 회원가입에 실패하였습니다<br />
            다시 한번 시도 해주세요.</P
          >
        </div>

        <a href="/sild/join_form.php" class="black_btn pos_fixed">회원가입</a>
      </div> -->
    </div>
    <sild-footer />
  </div>
</template>

<script>
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import AuthAPI from '@/apis/AuthAPI';
import useUserInfo from '../../composables/user/useUserInfo';

export default {
  components: { SildFooter, BackTitleBasket },
  setup(_, context) {
    const { info, setUserInfo } = useUserInfo();

    AuthAPI.updateUserStart({ mem_id: info?.mem_id }).then(({ data }) => {
      setUserInfo(data.result_data);
    });

    return {
      handleClickGoHome() {
        context.root.$router.push({ name: 'main' });
      },
    };
  },
};
</script>

<style></style>
