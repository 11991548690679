<template>
  <div class="wrap">
    <back-title-basket :title="$t('Join.FindLogin')" to="/auth/login">
      <div class="header_tab sec_nav">
        <ul class="disflex">
          <li class="width50 on">
            <a href="javascript:void()"> {{ $t('Join.FindId') }}</a>
          </li>
          <li class="width50">
            <a @click="$router.push('/auth/find-pw')">
              {{ $t('Join.FindPw') }}</a
            >
          </li>
        </ul>
      </div>
    </back-title-basket>
    <div class="layout_body">
      <div class="login_wrap">
        <!--아이디 찾기 탭 시작-->
        <div v-if="result === null">
          <div class="logo_box wrap_flex center_flex">
            <P class="font2">{{ $t('Join.FindIdSelect') }}</P>
          </div>
          <!-- <naver-login-item type="id" />
          <kakao-login-item type="id" /> -->
          <find-form :title="$t('Join.FindTypePhone')" className="e_phone">
            <input
              id="findName"
              v-model="phone.mem_username"
              type="text"
              :placeholder="$t('Join.InputName')"
            />
            <input
              id="findPhone"
              v-model="phone.mem_phone"
              type="text"
              :placeholder="$t('Join.InputPhone')"
            />
            <button class="black_btn font4" @click="handleClickPhone">
              {{ $t('Join.Confirm') }}
            </button>
          </find-form>
          <find-form :title="$t('Join.FindTypeEmail')" className="e_mail">
            <input
              id="findName"
              v-model="email.mem_username"
              type="text"
              :placeholder="$t('Join.InputName')"
            />
            <input
              id="findEmail"
              v-model="email.mem_email"
              type="text"
              :placeholder="$t('Join.InputEmail')"
            />
            <button class="black_btn font4" @click="handleClickEmail">
              {{ $t('Join.Confirm') }}
            </button>
          </find-form>
          <dl>
            <dd class="guide">{{ $t('Join.JoinRegisterInfo') }}</dd>
          </dl>
        </div>
        <template v-if="result">
          <!--아이디 찾기를 성공했을 때-->
          <div v-if="result.result" class="join_content wrap_flex center_flex">
            <p class="color37 check fort2">
              {{
                $t('Join.AlertFindId', {
                  Name: result.result_data.mem_username,
                  ID: result.result_data.mem_userid,
                })
              }}
              <br />
              {{
                `(${result.result_data.mem_register_datetime.substr(0, 10)})`
              }}
            </p>
            <a
              class="black_btn font4 pos_fixed0"
              @click="$router.push('/auth/login')"
            >
              {{ $t('Join.Login') }}
            </a>
          </div>

          <!--아이디 찾기를 실패했을 때-->
          <div
            v-if="!result.result && !phone.mem_phone"
            class="join_content wrap_flex center_flex"
          >
            <p class="color37 check" v-html="$t('Join.NotFindId')"></p>
            <div class="pos_fixed0 disflex">
              <button
                class="black_btn gray_btn font4"
                @click="$router.push('/auth/join/home')"
              >
                {{ $t('Join.Join') }}
              </button>
              <button class="black_btn font4" @click="handleClickReset">
                {{ $t('Join.FindAgain') }}
              </button>
            </div>
          </div>
          <!--아이디 찾기를 실패했을 때-->
          <div
            v-if="!result.result && !email.mem_email"
            class="join_content wrap_flex center_flex"
          >
            <p class="color37 check" v-html="$t('Join.NotFindId2')"></p>
            <div class="pos_fixed0 disflex">
              <button
                class="black_btn gray_btn font4"
                @click="$router.push('/auth/join/home')"
              >
                {{ $t('Join.Join') }}
              </button>
              <button class="black_btn font4" @click="handleClickReset">
                {{ $t('Join.FindAgain') }}
              </button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue-demi';
import BackTitleBasket from '../../../components/layouts/components/headers/BackTitleBasket.vue';
import FindForm from './components/FindForm.vue';
import AuthAPI from '@/apis/AuthAPI';
import {
  addBodyOverflowHidden,
  removeBodyOverflowHidden,
} from '../../../utils/CommonUtils';
import useAlert from '@Composables/dialogs/useAlert';

export default {
  components: { BackTitleBasket, FindForm },
  setup(_, context) {
    addBodyOverflowHidden();
    const { showAlert } = useAlert();
    const state = reactive({
      phone: {
        mem_username: '',
        mem_phone: '',
      },
      email: {
        mem_username: '',
        mem_email: '',
        send_email: false,
      },

      result: null,
    });
    watch(
      () => state.phone.mem_phone,
      () => {
        state.phone.mem_phone = state.phone.mem_phone.replace(/[^0-9]/g, '');
      }
    );
    watch(
      () => state.phone.mem_username,
      () => {
        state.phone.mem_username = state.phone.mem_username.replace(
          /[^a-z|A-Zㄱ-ㅎ|가-힣]/g,
          ''
        );
      }
    );
    watch(
      () => state.email.mem_username,
      () => {
        state.email.mem_username = state.email.mem_username.replace(
          /[^a-z|A-Zㄱ-ㅎ|가-힣]/g,
          ''
        );
      }
    );
    return {
      ...toRefs(state),
      async handleClickPhone() {
        if (!state.phone.mem_username) {
          showAlert(context.root.$t('Join.EnterName'));
          return false;
        } else if (!state.phone.mem_phone) {
          showAlert(context.root.$t('Join.ErrorEmptyPhone'));
          return false;
        }
        const { data } = await AuthAPI.findIdByPhone(state.phone);

        state.result = data;
      },
      async handleClickEmail() {
        if (!state.email.mem_email) {
          showAlert(context.root.$t('Join.EnterEmail'));
          return false;
        } else if (!state.email.mem_username) {
          showAlert(context.root.$t('Join.EnterName'));
          return false;
        }
        const { data } = await AuthAPI.findIdByEmail(state.email);
        state.result = data;
      },
      handleClickReset() {
        state.phone = {
          mem_username: '',
          mem_phone: '',
        };

        state.email = {
          mem_username: '',
          mem_email: '',
          send_email: false,
        };

        state.result = null;
      },
    };
  },
  destroyed() {
    removeBodyOverflowHidden();
  },
};
</script>

<style></style>
