<template>
  <div></div>
</template>

<script>
import AuthAPI from '@/apis/AuthAPI';

export default {
  setup(_, context) {
    const type = context.root.$route.params.type;

    const naver_id_login = new window.naver_id_login('BCQUYMbduNZUiY0iC1UU');

    naver_id_login.get_naver_userprofile('naverSignInCallback()');

    window.naverSignInCallback = async function () {
      const email = naver_id_login.getProfileData('email');

      const { data } = await AuthAPI.findIdSns({
        mem_email: email,
      });

      if (data.result === false) {
        alert('가입된 계정이 없습니다.');
        window.close();
        return;
      }

      const mem_id = data.result_data.mem_id;

      switch (type) {
        case 'id':
          opener.location.href = `/auth/find/result/id/${mem_id}`;
          break;
        case 'pw':
          opener.location.href = `/auth/reset-pw/${mem_id}`;
          break;
        default:
          break;
      }

      window.close();
    };
  },
};
</script>

<style></style>
