<template>
  <div></div>
</template>

<script>
import AuthAPI from '@/apis/AuthAPI';

const naver_id_login = new window.naver_id_login('IHI46bmH9Vf5zFfFJaJA');

window.naverSignInCallback = async function () {
  const id = naver_id_login.getProfileData('id');

  const { data } = await AuthAPI.loginSns(id, 'naver');

  localStorage.setItem('loginInfo', 'naver');
  if (data.result) {
    if (!data.result_data.mem_phone) {
      localStorage.setItem('temp_mem_id', data.result_data.mem_id);
      if(opener){
        opener.$router.replace('/auth/update-auth');
      }else{
        window.location.replace('/auth/update-auth');
      }
    } else {
      localStorage.setItem('mem_id', data.result_data.mem_id);
      if(opener){
        opener.$router.replace(localStorage.getItem('return.page') ?? '/');
      }else{
        window.location.replace(localStorage.getItem('return.page') ?? '/');
      }
    }
  } else {
    const email = naver_id_login.getProfileData('email');
    const name = naver_id_login.getProfileData('name');

    const { data } = await AuthAPI.joinSns(id, email, name, 'naver');

    localStorage.setItem('temp_mem_id', data.result_data.mem_id);
    localStorage.setItem('return.page', '/');

    if(opener){
      opener.$router.replace('/auth/update-auth');
    }else{
      window.location.replace('/auth/update-auth');
    }
  }

  window.close();
};

export default {
  setup() {
    naver_id_login.get_naver_userprofile('naverSignInCallback()');
  },
};
</script>

<style></style>
