<template>
  <div class="wrap">
    <back-title-basket :title="$t('Join.FindLogin')" to="/auth/login">
      <div class="header_tab sec_nav">
        <ul class="disflex">
          <li class="width50 on">
            <a href="javascript:void()"> $t('Join.FindId')</a>
          </li>
          <li class="width50">
            <a @click="$router.push('/auth/find-pw')">$t('Join.FindPw')</a>
          </li>
        </ul>
      </div>
    </back-title-basket>
    <div class="layout_body">
      <div v-if="result" class="login_wrap">
        <div v-if="result.result" class="join_content wrap_flex center_flex">
          <p v-if="value.mem_sns_type === 'email'" class="color37 check fort2">
            {{
              $t(
                'Join.AlertFindId',
                { name: value.mem_username },
                { id: value.mem_userid },
              )
            }}
            <br />
            {{ `(${value.mem_register_datetime.substr(0, 10)})` }}
          </p>

          <p v-if="value.mem_sns_type === 'kakao'" class="color37 check fort2">
            {{ $t('Join.SnsKakao') }}<br />
            {{ $t('Join.EmailMsg') }}
          </p>

          <p v-if="value.mem_sns_type === 'naver'" class="color37 check fort2">
            {{ $t('Join.SnsNaver') }}<br />
            {{ $t('Join.EmailMsg') }}
          </p>

          <a
            class="black_btn font4 pos_fixed0"
            @click="$router.push('/auth/login')"
          >
            {{ $t('Join.Login') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue-demi';
import AuthAPI from '@/apis/AuthAPI';
import BackTitleBasket from '../../../components/layouts/components/headers/BackTitleBasket.vue';
export default {
  components: { BackTitleBasket },
  setup(_, context) {
    const result = ref(null);
    const value = ref(null);

    const loadDatas = async () => {
      const id = context.root.$route.params.mem_id;
      const { data } = await AuthAPI.getMemberInfo(id);

      result.value = data;
      value.value = data.result_data;
    };

    loadDatas();

    return { result, value };
  },
};
</script>

<style></style>
