import { render, staticRenderFns } from "./JoinView5.vue?vue&type=template&id=0d2fc8ae&"
import script from "./JoinView5.vue?vue&type=script&lang=js&"
export * from "./JoinView5.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports