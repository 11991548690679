<template>
  <div class="wrap">
    <back-title-basket :title="$t('Join.Join')" />

    <div v-if="items" class="layout_body box_p60">
      <div class="joinForm_box">
        <div class="header_tab">
          <ul class="disflex">
            <li class="width33">
              <a class="pointer">{{ $t('Join.JoinInfo') }}</a>
            </li>
            <li class="width33">
              <a class="pointer">{{ $t('Join.JoinConcept') }}</a>
            </li>
            <li class="width33 on">
              <a class="pointer">{{ $t('Join.JoinStyle') }}</a>
            </li>
          </ul>
        </div>
        <div class="inner p60">
          <p class="font4 fontW500">
            {{ $t('Join.UserStyleOver') }}<br />
            {{ $t('Join.UserThing') }}
          </p>
          <span class="font2 pb14 disblock">
            {{ $t('Join.UserSure') }}
          </span>

          <article class="conp_box prefer">
            <div class="prod_swiper noswiper_prod p0">
              <ul>
                <li v-for="(item, idx) in items" :key="item.key">
                  <toggle-image
                    :key="item.key"
                    v-model="form.styles[idx]"
                    :item="item"
                  />
                </li>
                <li class="add_conp">
                  <toggle-image
                    :item="{ label: '' }"
                    fileName="plus-icon.svg"
                    :isChecked="false"
                    @click="handleClickETC"
                  />
                </li>
              </ul>
            </div>
          </article>
        </div>
        <div class="w100_btn_box disflex">
          <button class="black_btn gray_btn font4" @click="handleClickPrev">
            {{ $t('Join.Prev') }}
          </button>
          <button class="black_btn font4" @click="handleClickNext()">
            {{ $t('Join.Next') }}
          </button>
        </div>
      </div>
    </div>
    <sild-footer />
    <auth-etc-popup
      v-if="isShowETC"
      @close="isShowETC = false"
      @confirm="handleClickNext"
    />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import AuthAPI from '@/apis/AuthAPI';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import useJoin from '@/composables/user/useJoin';
import ToggleImage from '../../components/Inputs/ToggleImage/ToggleImage.vue';
import AuthEtcPopup from '@/views/auth/popups/AuthETCPopup.vue';
import useAlert from '@Composables/dialogs/useAlert';

export default {
  components: {
    SildFooter,
    BackTitleBasket,
    ToggleImage,
    AuthEtcPopup,
  },
  setup(_, context) {
    const { form, merge } = useJoin();
    const { showAlert } = useAlert();
    const state = reactive({
      form: { ...form, styles: null },
      etc: '',
      isShowETC: false,
      items: null,
    });

    if (!form.mem_height || !form.concepts) {
      context.root.$router.replace('/auth/join2');
    }

    if (form.styles) {
      state.form.styles = form.styles;
    }

    const loadDatas = async () => {
      const { data } = await AuthAPI.getStyleItems();

      if (!state.form.styles) {
        state.form.styles = new Array(data.length).fill(false);
      }

      state.items = data.map((item) => ({
        key: item.style_id,
        label: item.style_value,
        image: item.img_url,
      }));
    };

    loadDatas();

    return {
      info: form,
      ...toRefs(state),
      handleClickPrev() {
        merge({ ...state.form });

        context.root.$router.push('/auth/join3').catch(() => {});
      },
      async handleClickNext(etc) {
        const selected = state.items
          .filter((_, idx) => state.form.styles[idx])
          .map((item) => item.label);

        if (etc) selected.push(etc);
        if (selected.length < 3) {
          showAlert(context.root.$t('Join.ItemLength'));
          return false;
        }
        const params = { ...state.form, mem_style: selected.join(',') };
        console.log(params);
        const { data } = await AuthAPI.updateUserInfo(params);

        if (data.status === 'succ') {
          context.root.$router.push('/auth/join5').catch(() => {});
        } else {
          context.root.$router.push('/auth/error');
        }
      },
      handleClickETC() {
        state.isShowETC = true;
      },
      handleClickConfirm() {},
    };
  },
};
</script>
