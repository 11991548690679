<template>
  <down-full-up-popup title="포인트 정책" @close="$emit('close')">
    <div slot="body" class="inner p04">
      <ul class="agree_content">
        <li class="table_wrap">
          <div>
            <table class="table_box">
              <colgroup>
                <col style="width: 27%" />
                <col style="width: 73%" />
              </colgroup>
              <tbody>
                <tr>
                  <th>명칭</th>
                  <td>포인트</td>
                </tr>
                <tr>
                  <th>지급시기</th>
                  <td>
                    할인(쿠폰,적립금,할인) 금액 제외한 실 결제금액 기준 (1%
                    적립), 배송완료후 7일후
                  </td>
                </tr>
                <tr>
                  <th>사용가능 기준</th>
                  <td>1단위까지 사용가능</td>
                </tr>
                <tr>
                  <th>사용제한</th>
                  <td>
                    전액 포인트으로 구매가능하나, 적립은 불가<br />
                    (할인 적용 후 실결제금액 30,000원 이상 사용가능)
                  </td>
                </tr>
                <tr>
                  <th>소멸시기</th>
                  <td>1년</td>
                </tr>
                <tr>
                  <th>교환/반품시 적립금</th>
                  <td>
                    환불시 환급,<br />
                    부분환불 요청 시 해당 상품의 판매가 비중에 따른 환급
                  </td>
                </tr>
                <tr>
                  <th>상품후기 적립금</th>
                  <td>
                    구매 후 첫 후기 5,000포인트<br />
                    포토 상품평 1,000포인트<br />
                    일반 상품평 500 포인트<br />

                    작성완료 후 바로 지급<br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
      </ul>
    </div>
  </down-full-up-popup>
</template>

<script>
import DownFullUpPopup from '../../../components/popups/DownFullUpPopup.vue';

export default {
  components: { DownFullUpPopup },
};
</script>

<style></style>
