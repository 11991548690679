<template>
  <div class="wrap">
    <back-title-basket :title="$t('Join.Join')" to="/auth/login">
      <div class="header_tab sec_nav">
        <ul class="disflex">
          <li class="width50">
            <a @click="$router.push('/auth/find-id')">{{
              $t('Join.FindId')
            }}</a>
          </li>
          <li class="width50 on">
            <a @click="$router.push('/auth/find-pw')">{{
              $t('Join.FindPw')
            }}</a>
          </li>
        </ul>
      </div>
    </back-title-basket>
    <div class="layout_body">
      <div class="login_wrap">
        <!--비밀번호 찾기 창-->
        <div v-if="isChangePassword" class="logo_box wrap_flex center_flex">
          <p class="change_pw_tit">{{ $t('Join.EditPw') }}</p>
          <div class="first_input">
            <input
              id="change_pw"
              v-model="checkPassword"
              maxLength="15"
              type="password"
              :placeholder="$t('Join.NewPw')"
              @input="handleInputPw"
            />
          </div>
          <input
            id="check_change_pw"
            v-model="reCheckPassword"
            maxLength="15"
            type="password"
            :placeholder="$t('Join.NewPwAgain')"
          />
          <p v-show="sameNumberPw" class="error">
            {{ $t('Join.ErrorIdThree') }}
          </p>
          <p v-show="valueCheck || isShoNoticeMem_pw" class="error">
            {{ $t('Join.ErrorPwMixed') }}
          </p>
          <!--          <p class="error" v-show="isShoNoticeMem_pw">특수문자는 허용 되지 않습니다.</p>-->
          <div class="change_btn_wrap">
            <button
              class="change_submit_btn"
              type="submit"
              @click="chagenPasswords"
            >
              {{ $t('Join.Confirm') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BackTitleBasket from '../../../components/layouts/components/headers/BackTitleBasket.vue';
import { reactive, ref, toRefs } from 'vue-demi';
import AuthAPI from '@/apis/AuthAPI';
import useAlert from '@Composables/dialogs/useAlert';

export default {
  components: { BackTitleBasket },
  setup(_, context) {
    let isChangePassword = ref(true);
    let otpValue = context.root._route.params.otp;
    const { showAlert } = useAlert();
    const state = reactive({
      result: null,
      infoChangeValue: { otp: otpValue, mem_password: null },
      checkPassword: null,
      reCheckPassword: null,
      error: {},
      sameNumberPw: false,
      valueCheck: false,
      isShoNoticeMem_pw: false,
    });

    const chagenPasswords = async () => {
      state.error.sameNumberPw = checkSameNumber(state.checkPassword);

      if (state.checkPassword !== state.reCheckPassword) {
        state.checkPassword = '';
        state.reCheckPassword = '';
        //showAlert('비밀번호가 서로 다릅니다');
        showAlert(context.root.$t('Join.PwDiscord'));
        return false;
      }
      if (state.isShoNoticeMem_pw) {
        showAlert(context.root.$t('Join.ErrorPwMixed'));
        return false;
      }
      if (state.sameNumberPw) {
        showAlert(context.root.$t('Join.ErrorRepeat'));
        return false;
      }
      if (state.valueCheck) {
        showAlert(context.root.$t('Join.ErrorPwMixed'));
        return false;
      }
      if (state.checkPassword === state.reCheckPassword) {
        state.infoChangeValue.mem_password = state.checkPassword;

        const { data } = await AuthAPI.changePassWord(state.infoChangeValue);
        state.result = data;
        if (state.result) {
          localStorage.removeItem('mem_id');
          location.href = 'auth/login';
        }
      }
    };
    return {
      isChangePassword,
      chagenPasswords,
      ...toRefs(state),
      handleInputPw() {
        console.log('들어옴');
        state.error.sameNumberPw = checkSameNumber(state.checkPassword);
        state.valueCheck = state.error.sameNumberPw.valueCheck !== true;
        state.sameNumberPw = state.error.sameNumberPw.sameNumber;
        state.isShoNoticeMem_pw = state.error.sameNumberPw.isShoNoticeMem_pw;
      },
    };
  },
};
const sameNumber = [
  '111',
  '222',
  '333',
  '444',
  '555',
  '666',
  '777',
  '888',
  '999',
  '012',
  '123',
  '234',
  '345',
  '456',
  '567',
  '678',
  '789',
  '000',
];
const checkSameNumber = value => {
  let isSameNumber = false;
  let isCheck = {
    sameNumber: false,
    valueCheck: false,
    isShoNoticeMem_pw: false,
  };

  function CheckPass(str) {
    let isCheck = true;
    let reg = /(?=.*?[a-z])(?=.*?[A-Z]){6,15}/;
    if (!reg.test(str) || str.length < 6) {
      isCheck = false;
    }

    return isCheck;
  }

  function SpecialCharactersCheck(str) {
    let reg2 = /[~!@#$%^&*()_+|<>?:{}]/;
    return !reg2.test(str);
  }

  isCheck.isShoNoticeMem_pw = SpecialCharactersCheck(value);
  isCheck.valueCheck = CheckPass(value);
  sameNumber.forEach(str => {
    if (isSameNumber === false) {
      isSameNumber = value.indexOf(str) > -1;
    }
  });
  isCheck.sameNumber = isSameNumber;

  return isCheck;
};
</script>

<style>
.link_btn_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 50px;
}
.change_pw_tit {
  margin-bottom: 20px;
}
.first_input {
  margin-bottom: 20px;
  width: 100%;
}
.change_btn_wrap {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.change_submit_btn {
  background: #202020;
  text-align: center;
  font-size: 12px;
  color: #fff;
  width: 100%;
  padding: 15px 0;
}
</style>
