<template>
  <div>
    <back-title-basket :title="$t('Join.Join')" />

    <div class="layout_body box_p60">
      <div class="joinForm_box">
        <div class="header_tab">
          <ul class="disflex">
            <li class="width33">
              <a class="pointer">{{ $t('Join.JoinInfo') }}</a>
            </li>
            <li class="width33 on">
              <a class="pointer">{{ $t('Join.JoinConcept') }}</a>
            </li>
            <li class="width33">
              <a class="pointer">{{ $t('Join.JoinStyle') }}</a>
            </li>
          </ul>
        </div>
        <div class="inner p10">
          <p class="font4 fontW500 pb14">
            {{ form.mem_username }}{{ $t('Join.UserPrefer') }}<br />
            {{ $t('Join.UserDoubleChk') }}
          </p>

          <article v-if="form.items" class="conp_box">
            <div class="prod_swiper noswiper_prod p0">
              <ul>
                <li v-for="(item, idx) in form.items" :key="item.key">
                  <toggle-image
                    :key="item.key"
                    v-model="form.concepts[idx]"
                    :item="item"
                  />
                </li>
                <!--나의 컨셉 기타 추가 후 생기는 리스트-->
                <li class="add_conp">
                  <toggle-image
                    :item="{ label: '기타' }"
                    fileName="plus-icon.svg"
                    :isChecked="false"
                    @click="handleClickETC"
                  />
                </li>
              </ul>
            </div>
          </article>
        </div>
        <div class="w100_btn_box disflex">
          <button
            class="black_btn gray_btn font4"
            @click="$router.push('/auth/join2')"
          >
            {{ $t('Join.Prev') }}
          </button>
          <button class="black_btn font4" @click="handleClickNext()">
            {{ $t('Join.Next') }}
          </button>
        </div>
      </div>
      <auth-etc-popup
        v-if="isShowETC"
        @close="isShowETC = false"
        @confirm="handleClickNext"
      />
    </div>

    <sild-footer />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import useJoin from '@/composables/user/useJoin';
import ToggleImage from '../../components/Inputs/ToggleImage/ToggleImage.vue';
import AuthEtcPopup from '@/views/auth/popups/AuthETCPopup.vue';
import AuthAPI from '@/apis/AuthAPI';
import useAlert from '@Composables/dialogs/useAlert';
export default {
  components: {
    SildFooter,
    BackTitleBasket,
    ToggleImage,
    AuthEtcPopup,
  },
  setup(_, context) {
    const { form, merge } = useJoin();
    const { showAlert } = useAlert();
    const state = reactive({
      form: {
        concepts: null,
        items: null,
        ...form,
      },

      isShowETC: false,
    });

    if (!form.mem_height) {
      context.root.$router.replace('/auth/join2');
    }

    if (form.concepts) {
      state.form.concepts = form.concepts;
    }

    const loadDatas = async () => {
      const { data } = await AuthAPI.getConceptItems();

      if (!state.form.concepts) {
        state.form.concepts = new Array(data.length).fill(false);
      }

      state.form.items = data.map((item) => ({
        key: item.concept_id,
        label: item.concept_value,
        image: item.img_url,
      }));
    };

    loadDatas();

    return {
      ...toRefs(state),
      info: form,
      ...toRefs(state),
      handleClickSkip() {},
      handleClickNext(etc) {
        const selected = state.form.items
          .filter((_, idx) => state.form.concepts[idx])
          .map((item) => item.label);

        if (etc) selected.push(etc);
        if (selected.length < 1) {
          showAlert(context.root.$t('Join.ConceptItemLength'));
          return false;
        }
        const mem_concept = selected.join(',');

        merge({ ...state.form, mem_concept });

        context.root.$router.push('/auth/join4').catch(() => {});
      },
      handleClickETC() {
        state.isShowETC = true;
      },
      handleClickConfirm() {},
    };
  },
};
</script>
