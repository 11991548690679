<template>
  <!--비밀번호 수정 할때 보이는 레이아웃 시작-->
  <div class="wrap">
    <back-title-basket :title="$t('Join.FindLogin')">
      <div class="header_tab sec_nav">
        <ul class="disflex">
          <li class="width50">
            <a @click="$router.push('/auth/find-id')">{{
              $t('Join.FindId')
            }}</a>
          </li>
          <li class="width50 on">
            <a @click="$router.push('/auth/find-pw')">{{
              $t('Join.FindPw')
            }}</a>
          </li>
        </ul>
      </div>
    </back-title-basket>

    <div class="layout_body">
      <div v-if="result === false" class="login_wrap">
        <div class="logo_box wrap_flex center_flex">
          <p class="font2">{{ $t('Join.EditPw') }}</p>
        </div>
        <ul class="login_form pw_edit">
          <li class="pb10 pw_icon">
            <label for="new_pw"></label>
            <input
              id="id"
              v-model="mem_password"
              type="password"
              :placeholder="$t('Join.NewPw')"
            />
          </li>
          <li class="pb10 pw_icon">
            <label for="new_repw"></label>
            <input
              id="password"
              v-model="pwChk"
              type="password"
              :placeholder="$t('Join.NewPwAgain')"
            />
          </li>
          <li class="pb10">
            <button class="black_btn font4 confirm" @click="handleClickConfirm">
              {{ $t('Join.Confirm') }}
            </button>
          </li>
          <li class="guide">
            {{ $t('Join.Reconnect') }}
          </li>
        </ul>
      </div>

      <div v-if="result">
        <div class="edit_content wrap_flex center_flex">
          <div class="edit_img"></div>
          <P class="color37 pb14">{{ $t('Join.PwChange') }}</P>
          <span class="font2 colora8 align_center lineH8">
            {{ $t('Join.PwComplete') }}
          </span>
          <a class="black_btn pos_fixed0" @click="$router.push('/auth/login')">
            {{ $t('Join.Login') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import BackTitleBasket from '../../../components/layouts/components/headers/BackTitleBasket.vue';
import useAlert from '../../../composables/dialogs/useAlert';
import AuthAPI from '@/apis/AuthAPI';

export default {
  components: { BackTitleBasket },
  setup(_, context) {
    const { showAlert } = useAlert();

    const state = reactive({
      mem_password: '',
      pwChk: '',
      mem_id: context.root.$route.params.mem_id,
      result: false,
    });

    const validate = value => {
      const reg = /^[a-z0-9]{6,15}$/g;

      if (!reg.test(value)) {
        showAlert(context.root.$t('Join.ErrorPwMixed'));
        return false;
      }

      if (checkSameNumber(value)) {
        showAlert(context.root.$t('Join.ErrorPwSequence'));
        return false;
      }

      if (state.mem_password !== state.pwChk) {
        showAlert(context.root.$t('Join.ErrorPwSequence'));
        return false;
      }

      return true;
    };

    return {
      ...toRefs(state),
      async handleClickConfirm() {
        if (validate(state.mem_password)) {
          const { data } = await AuthAPI.resetPw(state);

          state.result = data.result;
        }
      },
    };
  },
};

const sameNumber = [
  '111',
  '222',
  '333',
  '444',
  '555',
  '666',
  '777',
  '888',
  '999',
  '012',
  '123',
  '234',
  '345',
  '456',
  '567',
  '678',
  '789',
];

const checkSameNumber = value => {
  let isSameNumber = false;

  sameNumber.forEach(str => {
    if (isSameNumber === false) {
      isSameNumber = value.indexOf(str) > -1;
    }
  });

  return isSameNumber;
};
</script>

<style></style>
