import { ref } from '@vue/composition-api';
import { defineStore } from 'pinia';

export default defineStore('useJoin', () => {
  const form = ref({});

  const merge = data => {
    form.value = { ...form.value, ...data };
  };

  const getForm = () => form.value;

  return { form, merge, getForm };
});
