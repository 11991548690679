<template>
  <div v-if="isShowPage" class="wrap">
    <header class="layout_header new_page_header">
      <div class="hinner disflex">
        <a class="back" @click="handleClickBack" />
        <h2 class="new_title brand_name">{{ $t('Join.IdVerification') }}</h2>
      </div>
      <slot />
    </header>
    <div class="layout_body">
      <div class="top_txtbox">
        <p>{{ $t('Join.RequestVerification') }}</p>
      </div>

      <div class="joinForm_box modify_wrap">
        <div>
          <ul class="wrap_flex userinfo">
            <li>
              <button class="auth_btn black_btn" @click="handleClickPhone">
                {{ $t('Join.DoIdVerification') }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <sild-footer /> -->
    <phone-form
      v-if="isShowPhoneForm"
      :datas="datas"
      @close="isShowPhoneForm = false"
      @complate="handleComplateAuth"
    />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import useUserInfo from '../../composables/user/useUserInfo';
import AuthAPI from '@/apis/AuthAPI';
import PhoneForm from './PhoneForm.vue';

export default {
  components: { PhoneForm },
  setup() {
    const { info, setUserInfo } = useUserInfo();
    const cert_num = localStorage.getItem('cert_num');
    const mem_sns_type = localStorage.getItem('loginInfo');

    const mem_id = localStorage.getItem('temp_mem_id');

    const state = reactive({
      isShowPage: !cert_num,
      isShowPhoneForm: false,
      datas: null,
    });

    const checkAuth = async () => {
      const { data } = await AuthAPI.getMemberAuthInfo({
        cert_num,
      });
      if (data !== null) {
        await handleComplateAuth(data);
      }
      state.isShowPage = true;
    };

    cert_num && checkAuth();

    const handleComplateAuth = async (data) => {
      const form = {
        // mem_id: info?.mem_id,
        mem_id,
        mem_sns_type,
        mem_age: data.birth_day,
        mem_username: data.name,
        mem_phone: data.phone_no,
        mem_gender: data.gender === 0 ? 'man' : 'woman',
      };

      const { data: newInfo } = await AuthAPI.authUserInfo(form);

      localStorage.setItem('mem_id', newInfo.mem_id);
      localStorage.setItem('temp_mem_id', '');
      localStorage.setItem('cert_num', '');

      await setUserInfo(newInfo);

      location.href = '/';
    };

    return {
      info,

      ...toRefs(state),

      handleComplateAuth,
      handleClickBack() {
        setUserInfo(null);
        window.$router.back();
      },
      async handleClickPhone() {
        // 팝업이 열려있는 경우 다시 열지않음
        if (state.isShowPhoneForm) return;

        const { data } = await AuthAPI.getPhoneInfo({
          code: process.env.VUE_APP_URLCODE_UPDATE,
          close_url: 'updateAuth',
        });

        localStorage.setItem('cert_num', data.certNum);

        state.datas = data;

        state.isShowPhoneForm = true;
      },
    };
  },
};
</script>

<style>
.auth_btn.black_btn {
  width: 100%;
  margin: 0;
}
</style>
