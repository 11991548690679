<template>
  <down-full-up-popup
    :title="$t('Setting.TermMarketing')"
    @close="$emit('close')"
  >
    <div slot="body" v-html="text" />
  </down-full-up-popup>
</template>

<script>
import DownFullUpPopup from '@Popups/DownFullUpPopup.vue';
import useShopInfo from '@Composables/shop/useShopInfo';

export default {
  components: { DownFullUpPopup },
  setup() {
    const { info } = useShopInfo();
    const text = info.private2.replace('{{업체명}}', '업체명!!!!!!!!!!!!');
    return {
      info,
      text,
    };
  },
};
</script>
<style>
.marketing-popup .pop_body * {
  font-size: 0.85rem;
  font-family: 'Noto Sans KR', 'sans-serif' !important;
}
</style>
