<template>
  <dl class="find_form">
    <dt :class="className" @click="isShow = !isShow">{{ title }}</dt>
    <dd :class="isShow ? 'show' : ''">
      <slot />
    </dd>
  </dl>
</template>

<script>
import { ref } from 'vue-demi';
export default {
  props: ['title', 'className'],
  setup() {
    const isShow = ref(false);

    return { isShow };
  },
};
</script>

<style></style>
