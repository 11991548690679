<template>
  <div class="wrap">
    <!-- header : END -->
    <back-title-basket :title="$t('Join.Login')" to="/mypage" />
    <!-- header : END -->
    <!-- body : START -->
    <div class="layout_body box_p0">
      <div class="login_wrap">
        <div class="logo_box wrap_flex center_flex">
          <p class="font2">{{ label }}</p>
        </div>
        <ul class="login_form">
          <li class="pb10">
            <label for="id"></label>
            <input
              id="id"
              v-model="form.mem_userid"
              :placeholder="$t('Join.EnterIdMixed')"
              type="text"
              @focusout="handleFocusOutId"
              @keydown="handleKeydown"
            />
            <p v-show="error.email" class="error">
              {{ $t('Join.InputEmailNone') }}
            </p>
            <p v-show="error.id" class="error">
              {{ $t('Join.InputIdNone') }} .
            </p>
          </li>
          <li class="pb10">
            <label for="password"></label>
            <input
              id="password"
              v-model="form.mem_password"
              :placeholder="$t('Join.EnterPw')"
              type="password"
              @keydown="handleKeydown"
            />
            <p v-show="error.password_empty" class="error">
              {{ $t('Join.InputPw') }}
            </p>
            <p v-show="error.password" class="error">
              {{ $t('Join.InputInfo') }}
            </p>
          </li>
        </ul>
        <button class="black_btn font4" @click="handleClickLogin">
          {{ $t('Join.Login') }}
        </button>
        <div>
          <input id="id_save" v-model="remember" type="checkbox" />
          <label class="chkbox color37 font2" for="id_save">
            {{ $t('Join.SaveId') }}
          </label>

          <ul class="login_list disflex">
            <li><a @click="$router.push('/auth/find-id')">아이디찾기</a></li>
            <li>
              <a @click="$router.push('/auth/find-pw')">비밀번호찾기</a>
            </li>
            <li>
              <router-link to="/auth/join/home"> 회원가입 </router-link>
            </li>
          </ul>
        </div>
        <div class="sns_list">
          <ul class="disflex">
            <li class="login_sns kakao" @click="handleClickKakao" />
            <li class="login_sns naver" @click="handleClickNaver" />
            <li class="login_sns google" @click="handleClickGoogle">
              <GoogleLogin
                :onSuccess="handleScucce"
                :params="googleLoginParams.client"
                :renderParams="googleLoginParams.render"
                style="opacity: 0"
              />
            </li>
            <!--            <li>-->
            <!--              <kakaoLogin-->
            <!--                :apiKey="kakaoClientId"-->
            <!--                @onFailure="kakaoRequestFailNew"-->
            <!--                @onSuccess="kakaoRequestSuccessNew"-->
            <!--              />-->
            <!--            </li>-->
            <!--            <li>-->
            <!--              <button @click="signInWithGoogle">GoogleOauth2.0</button>-->
            <!--            </li>-->
            <li class="login_sns apple" @click="handleClickApple" />
          </ul>
        </div>
      </div>
    </div>
    <div
      id="appleid-signin"
      data-border="true"
      data-color="black"
      data-type="sign in"
      style="display: none"
    />
    <div id="naver_id_login" style="display: none"></div>

    <sild-footer />
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from 'vue-demi';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import AuthAPI from '@/apis/AuthAPI';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import useUserInfo from '@/composables/user/useUserInfo';
import SnsLogin from '@/utils/SnsLoginUtils';
import GoogleLogin from 'vue-google-login';
import axios from 'axios';

export default {
  components: { SildFooter, BackTitleBasket, GoogleLogin },
  setup(_, context) {
    const goooogle = ref(null);
    const { setUserInfo } = useUserInfo();
    const mem_userid = localStorage.getItem('mem_userid');

    const state = reactive({
      form: {
        mem_userid: 'sinkertest',
        mem_password: 'sinkertest2',
      },
      remember: !!mem_userid,
      error: {
        id: false,
        email: false,
        password: false,
        password_empty: false,
      },
      clientId: process.env.VUE_APP_LOGIN_KEY_GOOGLE,
      kakaoClientId: process.env.VUE_APP_LOGIN_KEY_KAKAO,
    });

    const SnsAuth = new SnsLogin(context.root.$router, setUserInfo);
    const hashUrl = window.location.hash;
    if (hashUrl) {
      const hashUrl = window.location.hash.substring(1);
      const params = new URLSearchParams(hashUrl);
      const idToken = params.get('id_token');
      axios
        .get(
          `https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${idToken}`
        )
        .then((response) => {
          // API에서 반환한 응답을 처리합니다.
          const userInfo = {
            email: response.data.email,
            name: response.data.name,
            picture: response.data.picture,
            // 기타 필요한 사용자 정보
          };

          // 사용자 정보를 이용하여 필요한 작업을 수행합니다.
          console.log(userInfo);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    onMounted(() => {
      SnsAuth.init();
    });

    const loginInfo = localStorage.getItem('loginInfo');

    const label = loginInfo ? `가장 최근 로그인 : ${loginInfo}` : '';
    const handleScucce = () => {
      console.log('WWWW');
    };

    return {
      ...toRefs(state),
      goooogle,
      handleScucce,
      label,
      googleLoginParams: SnsAuth.getGoogleLoginParams(),
      googleRequestSuccess: SnsAuth.googleRequestSuccess,
      kakaoRequestSuccessNew(res) {
        console.log('@@@@@@@@@@@@@@@@@@@@@@@@');
        console.log(res);
        console.log('@@@@@@@@@@@@@@@@@@@@@@@@');
        // Auth.snsLogin(
        //   res.id,
        //   res.kakao_account.email,
        //   res.kakao_account.profile.nickname,
        //   'kakao'
        // );
      },
      kakaoRequestFailNew(error) {
        console.log(error);
      },
      handleGetGoogleData(params) {
        console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
        console.log('params', params);
        console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
      },
      handleClickKakao() {
        SnsAuth.kakaoLogin();
      },
      handleClickNaver() {
        SnsAuth.naverLogin();
      },
      handleClickApple() {
        SnsAuth.appleLogin();
      },
      handleClickGoogle() {
        SnsAuth.googleLogin();
      },
      async signInWithGoogle() {
        try {
          const authInstance = window.gapi.auth2.getAuthInstance();
          const googleUser = await authInstance.signIn();

          // 액세스 토큰을 가져옵니다.
          const accessToken = googleUser.getAuthResponse().access_token;
          // 필요한 경우 액세스 토큰을 서버에 전송합니다.
          console.log(googleUser);
          console.log(accessToken);
        } catch (error) {
          console.error('Error during Google sign-in:', error);
        }
      },
      async handleClickLogin() {
        if (state.form.mem_password === '') {
          state.error.password_empty = true;
          return;
        } else {
          state.error.password_empty = false;
        }
        const { data } = await AuthAPI.login(state.form);

        if (data.result) {
          const sectionTime = new Date().getTime();
          await setUserInfo(data.result_data);

          localStorage.setItem('mem_id', data.result_data.mem_id);
          localStorage.setItem('autoLogin', data.result_data.auto_login);
          localStorage.setItem('session_time', sectionTime);
          localStorage.setItem('push_yn', data.result_data.push_yn);

          if (state.remember) {
            localStorage.setItem('mem_userid', state.form.mem_userid);
          }

          const returnPage = localStorage.getItem('return.page') ?? '/';

          context.root.$router.push(returnPage);
        } else {
          const error = { ...state.error };

          error.password = true;

          state.error = error;
        }
      },
      async handleFocusOutId() {
        const emailReg =
          /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

        const error = {
          ...state.error,
          id: false,
          email: false,
        };

        const { data } = await AuthAPI.checkId(state.form.mem_userid);

        if (data.count === 0) {
          if (emailReg.test(state.form.mem_userid)) {
            error.email = true;
          } else {
            error.id = true;
          }
        }

        state.error = error;
      },

      handleKeydown(e) {
        if (e.key === 'Enter') {
          this.handleClickLogin();
        }
      },
    };
  },
  destroyed() {
    localStorage.setItem('return.page', '/');
  },
};
</script>

<style>
input[type='password'] {
  -webkit-text-security: disc;
}
</style>
