<template>
  <div class="wrap">
    <back-title-basket :title="$t('Join.Join')" />
    <div v-if="form.mem_username" class="layout_body box_p60">
      <div class="joinForm_box">
        <div class="header_tab">
          <ul class="disflex">
            <li class="width33 on">
              <a class="pointer">{{ $t('Join.JoinInfo') }}</a>
            </li>
            <li class="width33">
              <a class="pointer">{{ $t('Join.JoinConcept') }}</a>
            </li>
            <li class="width33">
              <a class="pointer">{{ $t('Join.JoinStyle') }}</a>
            </li>
          </ul>
        </div>
        <div class="underline join_text">
          <p class="font6 fontW500">
            {{ form.mem_username }}{{ $t('Join.JoinComplete') }}
          </p>
        </div>

        <div class="inner p60">
          <p class="font4 fontW500 pb20">
            {{ $t('Join.JoinExplain') }}<br />
            {{ $t('Join.JoinExplain2') }}
          </p>
          <h6 class="font2 align_center pb14">
            마이스타일을 설정해주신 분들께는 2,000P를 적립해 드립니다.
          </h6>
          <button class="radi_btn" @click="isShowPointPolicy = true">
            <strong>포인트</strong> 이렇게 쓰여요
          </button>
          <ul class="wrap_flex basic_info mt30">
            <li class="body_size">
              <label for="wandh" class="basic_tit">
                {{ form.mem_username }}{{ $t('Join.UserBodySize') }}
              </label>
              <div>
                <input-number
                  id="wandh"
                  v-model="form.mem_height"
                  type="number"
                  maxLength="3"
                  :placeholder="$t('Join.UserHeight')"
                />
              </div>
              <div>
                <input-number
                  id="wandh"
                  v-model="form.mem_weight"
                  type="number"
                  maxLength="3"
                  :placeholder="$t('Join.UserWeight')"
                />
              </div>
            </li>
          </ul>
        </div>
        <div class="w100_btn_box disflex">
          <button class="black_btn gray_btn font4" @click="handleClickSkip">
            {{ $t('Join.Skip') }}
          </button>
          <button class="black_btn font4" @click="handleClickNext">
            {{ $t('Join.Next') }}
          </button>
        </div>
      </div>
    </div>
    <sild-footer />
    <PointPolicyPopup
      v-if="isShowPointPolicy"
      @close="isShowPointPolicy = false"
    />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
// import AuthAPI from '@/apis/AuthAPI';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import InputNumber from '@/components/Inputs/InputNumber/InputNumber';
import useJoin from '@/composables/user/useJoin';
import useAlert from '../../composables/dialogs/useAlert';
import Strings from '@/consts/Strings';
import useUserInfo from '../../composables/user/useUserInfo';
import PointPolicyPopup from '@Views/auth/popups/PointPolicyPopup';

export default {
  components: { PointPolicyPopup, SildFooter, BackTitleBasket, InputNumber },
  setup(_, context) {
    const { form, merge } = useJoin();
    const { showAlert } = useAlert();
    const { getUserInfo } = useUserInfo();

    const state = reactive({
      isShowPointPolicy: false,
      form: {
        mem_height: '',
        mem_weight: '',
        ...form,
      },
    });

    if (!form.mem_id) {
      const loadMemberInfo = async () => {
        const data = await getUserInfo();

        if (data === null) {
          context.root.$router.replace('/auth/error');
        } else {
          state.form = {
            ...state.form,
            mem_id: data.mem_id,
            mem_username: data.mem_username,
          };
        }
      };
      loadMemberInfo();
    }

    const validate = () => {
      if (state.form.mem_height === '') {
        showAlert(Strings.validate_required_height);
        return false;
      }

      if (state.form.mem_weight === '') {
        showAlert(Strings.validate_required_weight);
        return false;
      }

      return true;
    };

    return {
      info: form,
      ...toRefs(state),
      handleClickSkip() {
        context.root.$router.push('/auth/join5').catch(() => {});
      },
      handleClickNext() {
        if (validate() === false) {
          showAlert(Strings.validate_required);
          return;
        }

        merge(state.form);

        context.root.$router.push('/auth/join3').catch(() => {});
      },
    };
  },
};
</script>

<style>
.allChk {
  border-bottom: 1px solid #d9d9d9;
}
.useragree label {
  padding: 10px 0;
}
</style>
