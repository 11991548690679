<template>
  <div class="wrap">
    <back-title-basket :title="$t('Join.FindLogin')" to="/auth/login">
      <div class="header_tab sec_nav">
        <ul class="disflex">
          <li class="width50">
            <a @click="$router.push('/auth/find-id')">{{
              $t('Join.FindId')
            }}</a>
          </li>
          <li class="width50 on">
            <a @click="$router.push('/auth/find-pw')">{{
              $t('Join.FindPw')
            }}</a>
          </li>
        </ul>
      </div>
    </back-title-basket>
    <div class="layout_body">
      <div class="login_wrap">
        <!--비밀번호 찾긱 창-->
        <!--아이디 찾기 탭 시작-->
        <!--        <div v-if="result === null && isChangePassword !== true">-->
        <div v-if="result === null">
          <div class="logo_box wrap_flex center_flex">
            <P class="font2">{{ $t('Join.FindPasswordSelect') }}</P>
          </div>
          <!--          <find-form title="휴대폰 번호로 찾기" className="e_phone">-->
          <!--            <div class="find_pw_form">-->
          <!--              <ul>-->
          <!--                <li>-->
          <!--                  <label for="name_input"></label>-->
          <!--                  <input-->
          <!--                    type="text"-->
          <!--                    id="name_input"-->
          <!--                    placeholder="이름을 입력해주세요."-->
          <!--                  />-->
          <!--                </li>-->
          <!--                <li class="disflex request">-->
          <!--                  <label for="phone_num"></label>-->
          <!--                  <input-->
          <!--                    type="text"-->
          <!--                    id="phone_num"-->
          <!--                    placeholder="휴대폰 번호를 입력해주세요."-->
          <!--                  />-->
          <!--                  <button class="font2">인증</button>-->
          <!--                  &lt;!&ndash;인증번호 오류 났을 때 버튼 ㄱ&ndash;&gt;-->
          <!--                  <button class="font2" style="display: none">다시요청</button>-->
          <!--                </li>-->
          <!--                &lt;!&ndash; 인증번호 요청시 보이는 input 박스ㄱ&ndash;&gt;-->
          <!--                <li class="disflex request">-->
          <!--                  <label for="finfNum"></label>-->
          <!--                  <input-->
          <!--                    type="text"-->
          <!--                    id="finfNum"-->
          <!--                    placeholder="인증번호 6자리"-->
          <!--                  />-->
          <!--                  <span class="font2">03 : 00</span>-->
          <!--                </li>-->
          <!--                &lt;!&ndash; <li>-->
          <!--                  <p class="ect_text colorR">-->
          <!--                    인증번호가 문자메시지로 발송되었습니다.-->
          <!--                  </p>-->
          <!--                  <p class="ect_text colorR">-->
          <!--                    인증번호를 정확하게 입력해주세요.-->
          <!--                  </p>-->
          <!--                  <p class="ect_text colorR">-->
          <!--                    제한시간 초과! 인증을 ‘다시요청’ 해주세요.-->
          <!--                  </p>-->
          <!--                  <p class="ect_text colorR">휴대폰인증을 진행해주세요.</p>-->
          <!--                </li> &ndash;&gt;-->
          <!--                <button class="black_btn font4">확인</button>-->
          <!--              </ul>-->
          <!--            </div>-->
          <!--          </find-form>-->
          <find-form :title="$t('Join.FindTypeEmail')" className="e_mail">
            <input
              id="findName"
              v-model="email.mem_username"
              :placeholder="$t('Join.InputName')"
              type="text"
            />
            <input
              id="findUserid"
              v-model="email.mem_userid"
              :placeholder="$t('Join.EnterId')"
              type="text"
            />
            <input
              id="findEmail"
              v-model="email.mem_email"
              :placeholder="$t('Join.InputEmail')"
              type="text"
            />
            <button class="black_btn font4" @click="handleClickEmail">
              {{ $t('Join.Confirm') }}
            </button>
          </find-form>
          <dl>
            <dd class="guide">{{ $t('Join.JoinRegisterInfo') }}</dd>
          </dl>
        </div>
        <template v-if="result">
          <!--아이디 찾기를 성공했을 때-->
          <div v-if="result.result" class="join_content wrap_flex center_flex">
            <p class="color37 check fort2">
              {{ `${result.result_data.mem_username} ` }}
              {{ $t('Join.PostEmail') }}
              <br />
              {{ $t('Join.MailPostPw') }}
            </p>
            <div class="link_btn_wrap pos_fixed0">
              <a
                class="black_btn gray_btn goto_main font4"
                @click="$router.push('/')"
              >
                {{ $t('Join.RouterHome') }}
              </a>
              <a
                class="black_btn goto_login font4"
                @click="$router.push('/auth/login')"
              >
                {{ $t('Join.Login') }}
              </a>
            </div>
          </div>

          <!--아이디 찾기를 실패했을 때-->
          <div v-if="!result.result" class="join_content wrap_flex center_flex">
            <P class="color37 check">{{ $t('Join.NotFindPw') }}</P>
            <div class="disflex pos_fixed0" style="margin-top: 20px">
              <button
                class="black_btn gray_btn font4"
                @click="$router.push('/auth/join/home')"
              >
                {{ $t('Join.Join') }}
              </button>
              <button class="black_btn font4" @click="handleClickReset">
                {{ $t('Join.FindAgain') }}
              </button>
            </div>
          </div>
        </template>
        <!--아이디 찾기 탭 끝-->
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs, watch } from 'vue-demi';
import BackTitleBasket from '../../../components/layouts/components/headers/BackTitleBasket.vue';
import FindForm from './components/FindForm.vue';
import AuthAPI from '@/apis/AuthAPI';
import {
  addBodyOverflowHidden,
  removeBodyOverflowHidden,
} from '../../../utils/CommonUtils';
import useAlert from '@Composables/dialogs/useAlert';

export default {
  components: { BackTitleBasket, FindForm },
  setup(_, context) {
    let isChangePassword = ref(true);
    addBodyOverflowHidden();
    const { showAlert } = useAlert();

    const state = reactive({
      phone: {
        mem_username: '',
        mem_phone: '',
      },
      email: {
        mem_username: '',
        mem_email: '',
        mem_userid: '',
      },

      result: null,
    });
    watch(
      () => state.email.mem_username,
      () => {
        state.email.mem_username = state.email.mem_username.replace(
          /[^a-z|A-Zㄱ-ㅎ|가-힣]/g,
          ''
        );
      }
    );
    return {
      ...toRefs(state),
      isChangePassword,
      async handleClickPhone() {
        const { data } = await AuthAPI.findPwByPhone(state.phone);

        state.result = data;
      },
      async handleClickEmail() {
        console.log('-> state.email', state.email);
        if (!state.email.mem_userid) {
          showAlert(context.root.$t('Join.EnterId'));
          return false;
        } else if (!state.email.mem_email) {
          showAlert(context.root.$t('Join.EnterEmail'));
          return false;
        } else if (!state.email.mem_username) {
          showAlert(context.root.$t('Join.EnterName'));
          return false;
        }
        const { data } = await AuthAPI.findPwByEmail(state.email);

        state.result = data;
      },
      handleClickReset() {
        state.phone = {
          mem_username: '',
          mem_phone: '',
        };

        state.email = {
          mem_username: '',
          mem_email: '',
        };

        state.result = null;
      },
    };
  },
  destroyed() {
    removeBodyOverflowHidden();
  },
};
</script>

<style>
.link_btn_wrap {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (min-width: 1240px) {
  .link_btn_wrap {
    max-width: 360px;
  }
}
</style>
