<template>
  <div class="wrap">
    <!-- header : END -->
    <back-title-basket :title="$t('Join.Join')" to="/auth/login" />
    <!-- header : END -->
    <!-- body : START -->
    <div class="layout_body box_p0">
      <div class="join_box">
        <div class="logo_box wrap_flex center_flex">
          <div class="logo_img"></div>
          <P class="color37"> {{ $t('Join.WelcomeMsg') }}</P>
        </div>
        <div class="sns_list">
          <ul class="disflex">
            <li class="login_sns kakao" @click="handleClickKakao" />
            <li class="login_sns naver" @click="handleClickNaver" />
            <li class="login_sns google">
              <GoogleLogin
                :params="googleLoginParams.client"
                :renderParams="googleLoginParams.render"
                :onSuccess="googleRequestSuccess"
                style="opacity: 0"
              />
            </li>
            <li class="login_sns apple" @click="handleClickApple" />
          </ul>
        </div>
        <div class="loginfoot_box wrap_flex">
          <router-link class="black_btn" to="/auth/join">
            SILD 회원가입
          </router-link>
          <div class="login_text">
            <span class="colora8 font2"> {{ $t('Join.LoginText') }}</span>
            <a class="font2" @click="$router.push('/auth/login')">
              {{ $t('Join.Login') }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div
      id="appleid-signin"
      data-color="black"
      data-border="true"
      data-type="sign in"
      style="display: none"
    />
    <div id="naver_id_login" style="display: none"></div>
    <sild-footer />
  </div>
</template>

<script>
import SildFooter from '@/components/layouts/components/footers/SildFooter.vue';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import useUserInfo from '@/composables/user/useUserInfo';
import SnsLogin from '@/utils/SnsLoginUtils';
import { onMounted } from 'vue-demi';
import GoogleLogin from 'vue-google-login';

export default {
  components: { SildFooter, BackTitleBasket, GoogleLogin },
  setup(_, context) {
    const { setUserInfo } = useUserInfo();
    const SnsAuth = new SnsLogin(context.root.$router, setUserInfo);

    onMounted(() => {
      SnsAuth.init();
    });

    return {
      googleLoginParams: SnsAuth.getGoogleLoginParams(),
      googleRequestSuccess: SnsAuth.googleRequestSuccess,
      handleClickKakao() {
        SnsAuth.kakaoLogin();
      },
      handleClickNaver() {
        SnsAuth.naverLogin();
      },
      handleClickApple() {
        SnsAuth.appleLogin();
      },
    };
  },
};
</script>

<style></style>
